import { Button, Paper } from '@mui/material';
import { Postup_APIS } from 'api/API';
import Axios from 'api/Axios';
import React, { useEffect, useState } from 'react';
import '../Data Collection/style.css';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Email_fields } from '../meta-data/Fields';

const View_Email_Status = ({ setIsLoading, uploadAssetsData, isLoading, close }) => {
  const [emailData, setEmailData] = useState([]);
  const get_Email_Status = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Postup_APIS.Email_Status, {
        merger_name: uploadAssetsData.file_name,
      });
      if (response.status === 200) {
        setEmailData(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Files');
      setIsLoading(false);
    }
  };

  const columns = [
    { id: 'sno', label: '#' },
    ...Email_fields.map(item => ({ id: item, label: item })),
  ];

  useEffect(() => {
    get_Email_Status();
  }, []);

  return (
    <>
      <div>
        <TableContainer
          className="chips"
          component={Paper}
          style={{
            border: '1px solid black',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Table className="dataTable postTable">
            <TableHead>
              <TableRow
                style={{
                  background: '#15223f',
                  border: 'none',
                }}
              >
                {columns.map(column => (
                  <TableCell
                    style={{
                      textAlign: 'center',
                      color: '#fff',
                      border: 'none',
                      padding: '0px 20px',
                    }}
                    className="headings"
                    // style={{ color: "#fff" }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {emailData?.data?.length > 0 ? (
                emailData?.data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="center">{index + 1}</TableCell>
                    <TableCell className="center">{item.clicks}</TableCell>
                    <TableCell className="center">{item.blockBounces}</TableCell>
                    <TableCell className="center">{item.hardBounces}</TableCell>
                    <TableCell className="center">{item.softBounces}</TableCell>
                    <TableCell className="center">{item.title}</TableCell>
                    <TableCell className="center">{item.uniqueClicks}</TableCell>
                    <TableCell className="center">{item.uniqueOpens}</TableCell>
                    <TableCell className="center">{item.uniqueUsers}</TableCell>
                    <TableCell className="center">{item.scheduled}</TableCell>
                    <TableCell className="center">{item.sent}</TableCell>
                    <TableCell className="center">{item.opens}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: 'center',
                    }}
                    colSpan={7}
                  >
                    Record Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Button
          size="large"
          variant="contained"
          style={{
            background: '#C62828',
            marginRight: '10px',
            color: isLoading === true && '#fff',
          }}
          onClick={close}
          disabled={isLoading === true ?? true}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default View_Email_Status;
