const STAGING = "preview"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "lw5upf0t9oilr42q8pympsb5kc9r69yr1uwzefdk3dfviyiq",
  dev: "lw5upf0t9oilr42q8pympsb5kc9r69yr1uwzefdk3dfviyiq",
  prod: "omkjpeeljai4s7i4zimgk24v82bud1wslchehtgqgc8kjate",
  preview: "zjczddcqk4ax0s2egwhzcnlzs1e4am1kwqpd8g6xdy5i6q0k",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}